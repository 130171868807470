<template>
  <div class="introduce_wrap">
    <div class="tit_1 top">(주)리뷰앤메이크머니</div>
    <div class="tit_1" id="tab1">진실한 리뷰의 가치를 아는 기업</div>
    <div class="txt">
      <b>램</b>(<b>RAMM</b>, Review and Make Money의 약칭, 특허등록
      10-2430***-****)은 돈을 넘어 그 이상의 가치를 추구합니다. <b>램</b>은
      리뷰를 통해 사람과 사람, 사람과 기업, 사람과 상품 및 서비스를 연결시켜
      줍니다. <b>램</b>이 생각하는 리뷰는 상품과 서비스 이면에 깃든, 인간의
      숨결과 품격에 대한 통찰입니다. 리뷰 없는 세상도 있습니다. 리뷰의 본질은
      '진실'입니다.진실은 이 세상을 더 나은 곳으로 만듭니다.
      (주)리뷰앤메이크머니는 진실한 리뷰의 가치를 이해하고 존중하며 이를 통해
      사람 사이의 소통을 가능하게 하는 인터넷 플랫폼 기업입니다.
    </div>

    <div class="tit_1" id="tab2">모든 리뷰는 동등한 가치를 지닐까요?</div>
    <div class="txt">
      모든 리뷰가 동등한 가치를 지니는 것은 아닙니다. 진실한 리뷰와 거짓된 리뷰,
      공정한 리뷰와 과장, 왜곡, 조작, 사기성 리뷰는 구분되어야 합니다.
      <b>램</b>은 리뷰 이용자들의 활동을 토대로 리뷰의 신뢰도를 확립합니다. 또
      이를 토대로 악의적인 리뷰를 걸러내고 좋은 리뷰를 상위에 노출하는 선순환의
      시스템을 제공합니다. <b>램</b>의 생태계 안에서 리뷰어는 자신의 노동에 대한
      합당한 보상을, 소비자는 더 효율적이고 지혜로운 쇼핑을, 좋은 상품과
      서비스를 제공하는 기업은 매출 증대라는 이익을 누리게 됩니다.
      <div class="img">
        <img src="@/assets/images/sub/introduce_01.jpg" alt="" />
      </div>
    </div>

    <div class="tit_1" id="tab3">선순환의 생태계에 동참하세요!</div>
    <div class="tit_2" id="tab4">· 리뷰어로서</div>
    <div class="txt">
      청소년부터 어르신까지, <b>램</b>이 제공하는 모든 영역에서 여러분은 모두
      리뷰어가 될 수 있습니다. <b>램</b>에서 좋은 리뷰들은 상위에 노출되고, 이
      리뷰들을 통해 상품 및 서비스 구매가 이루어지며, 리뷰어들은 구매 건수에
      따라 보상 포인트를 지급받습니다(<b>램</b> 오픈마켓과 제휴몰의 경우만
      해당). <b>램</b>에서 정직한 리뷰어들은 자신들의 리뷰에 대한 합당한 보상뿐
      아니라, 신뢰 사회를 구축하는 리더들로서 동기부여와 자부심, 열정과 선한
      영향력을 갖게 됩니다.
    </div>
    <div class="tit_2" id="tab5">· 제휴사로서</div>
    <div class="txt">
      상품과 서비스를 제공하는 기업은 누구나 <b>램</b>의 제휴사가 될 수
      있습니다. <b>램</b>의 생태계에서 제휴사들은 검증된 리뷰어들을 통해 상품과
      서비스를 판매할 수 있으므로 기업의 신뢰도 상승, 이미지 제고, 광고효과,
      매출 증대, 판매영토 확보라는 유익을 얻게 됩니다. 또 제휴사들은 리뷰어들의
      피드백을 통해 소비자들의 니즈를 파악, 효율적인 상품 및 서비스의 기획,
      생산, 판매가 가능하게 됩니다. 제휴사들은 판매건수에 따라 <b>램</b>에
      인프라 사용료를 지불하고 <b>램</b>은 이 중 일부를 리뷰어에게 보상 포인트로
      지급하므로 불필요하고 불확실한 광고비 남용을 줄일 수 있습니다. 인프라
      사용료는 기회비용입니다.
    </div>
    <div class="tit_2" id="tab6">· 리뷰 이용자로서</div>
    <div class="txt">
      상품과 서비스를 구매하려는 분은 누구나 <b>램</b>의 리뷰 이용자가 될 수
      있습니다. 리뷰 이용자들은 신뢰도 높은 리뷰들을 통해 상품과 서비스에 대한
      정보를 얻고 이를 통해 상품 및 서비스를 구매하게 되므로, 잘못된 판단에 의한
      소비나 낭비, 소비자 기만으로부터 자유로워집니다. 아울러 리뷰 이용자들은
      리뷰를 통한 구매나 구매 후 리뷰 만족도, 제품 평점 매기기 등을 통해 리뷰의
      신뢰도 확립에 기여함으로써, 정직한 리뷰 문화, 건강한 소비 생태계 구축을
      가능하게 됩니다. 리뷰 이용자들은 건강한 리뷰 문화 확립의 토양이요
      주체입니다.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: this.$route.query.tab || null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.tab) {
        document.querySelector(`#${this.tab}`).scrollIntoView();
      }
    });
  },
};
</script>

<style></style>
